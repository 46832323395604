.outline-black {
    -webkit-text-stroke: 1px black;
    color: white;
  }
  
  /* Frosty shimmer animation */
  .shimmer-title {
    position: relative;
    display: inline-block;
    overflow: hidden;
  }
  
  .shimmer-title::after {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(255, 255, 255, 0.6),
      transparent
    );
    animation: shimmer 2s infinite;
  }
  
  @keyframes shimmer {
    0% {
      left: -100%;
    }
    50% {
      left: 100%;
    }
    100% {
      left: 100%;
    }
  }
  