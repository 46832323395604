.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

.float {
  animation: float 3s ease-in-out infinite;
}

@keyframes aurora {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.aurora-footer {
  background: linear-gradient(270deg, #1e3a8a, #0ea5e9, #9333ea);
  background-size: 600% 600%;
  animation: aurora 10s ease infinite;
}
/* App.css or index.css */
@keyframes float {
  0% { transform: translateY(0); }
  50% { transform: translateY(-8px); }
  100% { transform: translateY(0); }
}

.float {
  animation: float 3s ease-in-out infinite;
}

@keyframes aurora {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.aurora-footer {
  background: linear-gradient(270deg, #1e3a8a, #0ea5e9, #9333ea);
  background-size: 600% 600%;
  animation: aurora 10s ease infinite;
}
.frosty-snowflake {
  position: absolute;
  font-size: 1.5rem;
  opacity: 0.8;
  animation: floatflake 6s ease-in-out infinite;
  pointer-events: none;
}

@keyframes floatflake {
  0% {
    transform: translateY(0) translateX(0) rotate(0deg);
    opacity: 0.8;
  }
  50% {
    transform: translateY(30px) translateX(10px) rotate(20deg);
    opacity: 0.6;
  }
  100% {
    transform: translateY(60px) translateX(-10px) rotate(360deg);
    opacity: 0.2;
  }
}
.font-sourgummy {
  font-family: 'Sour Gummy', sans-serif;
  font-weight: 600;
}

@keyframes steam-rise {
  0% {
    opacity: 0.4;
    transform: translateY(0) scale(1);
  }
  50% {
    opacity: 0.2;
    transform: translateY(-15px) scale(1.05);
  }
  100% {
    opacity: 0;
    transform: translateY(-30px) scale(1.1);
  }
}

.animate-steam-slow {
  animation: steam-rise 4s ease-in-out infinite;
}

.animate-steam-medium {
  animation: steam-rise 3s ease-in-out infinite;
}

.animate-steam-fast {
  animation: steam-rise 2.5s ease-in-out infinite;
}
@keyframes jiggle {
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
}

.animate-jiggle {
  animation: jiggle 3.5s ease-in-out infinite;
}
